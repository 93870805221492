import { call, put, takeLatest, select } from "redux-saga/effects";

import { getCoupon, validateCoupon } from "./CartApis";
import * as ACTIONS from "./CartActions";
import * as TYPES from "./CartTypes";
import { setErrorMessage } from "../Error/ErrorAction";
import { ALL_DELIVERY_TYPES, COUPONS, CURRENCY } from "../../utils/Constants";

// Replace with your sagas
export function* sagasCouponRequest({ payload }) {
  try {
    const hotelKey = payload;
    if (!hotelKey) {
      throw new Error("Something went wrong with the hotel key");
    }

    const response = yield call(getCoupon, hotelKey);
    if (!response?.data?.data) {
      throw new Error("Something went wrong with the response");
    }

    yield put(ACTIONS.getCouponReceive(response.data.data));
  } catch (err) {
    yield put(ACTIONS.getCouponReceive([]));
  }
}

// export function* sagasValidateCoupon({ payload }) {
//   yield put(ACTIONS.IsValidating(true)); // Start the validation process
//   try {
//     const { customerId, code } = payload;

//     // Check if customerId is present
//     if (!customerId) {
//       throw new Error("Something went wrong with the user id");
//     }

//     // Make the API call to validate the coupon
//     const response = yield call(validateCoupon, customerId, code);
//     yield put(ACTIONS.IsValidating(false)); // End validation process
//     yield put(ACTIONS.setCouponValidationResult(false));
//     // Log the API response for debugging
//     if (response?.data?.status) {
//       // If coupon validation is successful
//       yield put(ACTIONS.setCouponValidationResult(true));
//     } else {
//       // If coupon validation fails

//       yield put(ACTIONS.setCouponValidationResult(false));
//     }

//     const coupon = response?.data?.data;
//     if (coupon?.id) {
//       const { priceSummary, checkoutDetails, data } = yield select(
//         (state) => state.cart
//       );

//       const checkItemDiscountApplicability = (coupon) => {
//         if (
//           coupon?.rules?.type === COUPONS.ITEM_DISCOUNT &&
//           coupon?.items &&
//           coupon?.items?.length > 0
//         ) {
//           const applicableItems = JSON.parse(coupon?.items) || [];
//           const validItemsInCart = data?.filter((item) =>
//             applicableItems.includes(item.itemId)
//           );

//           if (
//             applicableItems?.length === 1 &&
//             coupon?.result?.DISCOUNT === 100 &&
//             validItemsInCart?.length === 1 &&
//             validItemsInCart[0]?.count > 1
//           ) {
//             return false;
//           } else {
//             return true;
//           }
//         } else return true;
//       };

//       // Check coupon applicability
//       if (checkItemDiscountApplicability(coupon)) {
//         const applicableItems = JSON.parse(coupon?.items) || [];
//         const validItemsInCart = data?.filter((item) =>
//           applicableItems.includes(item.itemId)
//         );

//         if (
//           coupon?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
//           coupon?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES
//         ) {
//           if (
//             Number(priceSummary?.subTotal) >
//             Number(coupon?.rules?.MIN_ORDER_VALUE)
//           ) {
//             yield put(ACTIONS.getCouponReceive([coupon]));
//           } else {
//             yield put(
//               setErrorMessage({
//                 message: `Order value should be more than ${CURRENCY.SIGN}${coupon?.rules?.MIN_ORDER_VALUE}`,
//                 showAs: "dialog",
//               })
//             );
//           }
//         } else {
//           yield put(
//             setErrorMessage({
//               message: `Only applicable for ${
//                 coupon?.rules?.APPLICABLE_FOR === COUPONS.CART_DISCOUNT
//                   ? "cart discount"
//                   : "item discount"
//               }`,
//               showAs: "dialog",
//             })
//           );
//         }
//       } else {
//         yield put(
//           setErrorMessage({
//             message: response?.data?.message || "Invalid Coupon",
//             showAs: "dialog",
//           })
//         );
//       }
//     } else {
//       yield put(
//         setErrorMessage({
//           message: response?.data?.message || "Invalid Coupon",
//           showAs: "dialog",
//         })
//       );
//     }
//   } catch (err) {
//     console.error("Error during coupon validation:", err); // Enhanced logging
//     yield put(
//       setErrorMessage({
//         message: "Something went wrong",
//         showAs: "dialog",
//       })
//     );
//   }
// }
export function* sagasValidateCoupon({ state, payload }) {
  try {
    const { hotelId, code } = payload;
    if (!hotelId) {
      throw new Error("Something went wrong with the hotel id");
    }

    const response = yield call(validateCoupon, hotelId, code);
    const coupon = response?.data?.data;
    //checking coupon is applicabile
    if (coupon?.id) {
      const { priceSummary, checkoutDetails, data } = yield select(
        (state) => state.cart
      );

      //   const getPrice = (coupon) => {
      //     if (coupon?.rules?.type === COUPONS.ITEM_DISCOUNT) {
      //       const applicableItems = JSON.parse(coupon?.items) || [];
      //       const validItemsInCart = data?.filter((item) =>
      //         applicableItems.includes(item.itemId)
      //       );
      //       let itemTotal = 0;
      //       validItemsInCart.forEach((item) => {
      //         const itemPrice = item?.count * item?.sale_price;
      //         itemTotal = itemTotal + itemPrice;
      //       });
      //       return Number(itemTotal);
      //     } else {
      //       return Number(priceSummary?.subTotal);
      //     }
      //   };

      const checkItemDiscountApplicability = (coupon) => {
        if (
          coupon?.rules?.type === COUPONS.ITEM_DISCOUNT &&
          coupon?.items &&
          coupon?.items?.length > 2
        ) {
          const applicableItems = JSON.parse(coupon?.items) || [];
          const validItemsInCart = data?.filter((item) =>
            applicableItems.includes(item.itemId)
          );
          // This condition for: 100% discount item can have only 1 quantity of that particular item
          if (
            applicableItems?.length === 1 &&
            coupon?.result?.DISCOUNT === 100 &&
            validItemsInCart?.length === 1 &&
            validItemsInCart[0]?.count > 1
          ) {
            return false;
          } else return !!validItemsInCart.length;
        } else return true;
      };

      //check if it is exposed coupon or not
      if (checkItemDiscountApplicability(coupon)) {
        // check greater than subtotal
        if (
          coupon?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
          coupon?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES
        ) {
          //   check delivery type
          if (
            Number(priceSummary?.subTotal) >
            Number(coupon?.rules?.MIN_ORDER_VALUE)
          ) {
            //if coupon is applicable
            yield put(ACTIONS.selectCoupon(coupon));
          } else {
            //if coupon is not applicable
            yield put(
              setErrorMessage({
                message: `Order value should be more than ${CURRENCY.SIGN}${coupon?.rules?.MIN_ORDER_VALUE}`,
                showAs: "dialog",
              })
            );
          }
        } else {
          //if coupon is not applicable
          yield put(
            setErrorMessage({
              message: `Only applicable for ${
                coupon?.rules?.APPLICABLE_FOR === COUPONS.CART_DISCOUNT
                  ? "cart discount"
                  : "item discount"
              }`,
              showAs: "dialog",
            })
          );
        }
      } else {
        //if coupon is not applicable
        yield put(
          setErrorMessage({
            message: `Invalid coupon`,
            showAs: "dialog",
          })
        );
      }

      //   if (
      //     Number(priceSummary?.subTotal) >
      //       Number(coupon?.rules?.MIN_ORDER_VALUE) &&
      //     (coupon?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
      //       coupon?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES)
      //   ) {
      //     //if coupon is applicable
      //     yield put(ACTIONS.selectCoupon(coupon));
      //   } else {
      //     //if coupon is not applicable
      //     yield put(
      //       setErrorMessage({
      //         message: `Minimum order value is atleast $${coupon?.rules?.MIN_ORDER_VALUE}`,
      //         showAs: "dialog",
      //       })
      //     );
      //   }
    } else {
      yield put(
        setErrorMessage({
          message: "Invalid coupon",
          showAs: "dialog",
        })
      );
    }
  } catch (err) {
    yield put(
      setErrorMessage({
        message: "Something went wrong",
        showAs: "dialog",
      })
    );
  }
}

export function* CartSaga() {
  yield takeLatest(TYPES.GET_COUPON_REQUEST, sagasCouponRequest);
}

export function* VerifyCouponSaga() {
  yield takeLatest(TYPES.VALIDATE_COUPON, sagasValidateCoupon);
}
