import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./SelectOrderType.style.js";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeliveryTime from "../DeliveryTime/DeliveryTime.js";
import DateSlotPicker from "../../Checkout/DateSlotPicker/index.js";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_TYPES } from "../../../utils/Constants.js";
import { generateDateSlots } from "../../../utils/utils.js";
import TimePicker from "../../Checkout/TimePicker/index.js";
import moment from "moment";
import { Info } from "@material-ui/icons";
import { clearTipAmount } from "../../../store/Cart/CartActions.js";

const SelectOrderType = ({
  value = null,
  onSelect = () => {},
  formValues,
  handleClose,
  onDateAndTimeSelect,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const [selectedOderType, setOrderType] = useState(value);
  const [dateSlots, setDateSlots] = useState([]);
  const [dateSlotsError, setDateSlotsError] = useState(false);
  const [date, setDate] = useState(null);
  const [typeOfOrder, setTypeOfOrder] = useState("SCHEDULED");
  const [time, setTime] = useState(null);
  const { theme } = useSelector((state) => state?.theme);
  const [orderTimeType, setOrderTimeType] = useState(null);
  const hasMounted = useRef(false);
  const {
    getOrderTypes,
    getDeliveryIntervalSlots,
    getTodayDeliveryIntervalSlots,
    isAsapAvailable,
    isMultipleOrderType,
    getAsapTime,
  } = useDynamicHotelData();
  const [newChange, setNewChange] = useState(false);

  // console.log("is asapppp", isAsapAvailable);
  // console.log("hotel?.openingAvailable", hotel?.openingAvailable);

  // console.log("dateSlots", dateSlots);
  // console.log("formValues", formValues);

  // console.log("iss asaapp", isAsapAvailable);
  // console.log("");

  const onOrderTypeSelect = (fieldName, type) => {
    setOrderType(type);
    onSelect(fieldName, type);
    dispatch(clearTipAmount());
  };

  // useEffect(() => {
  //   // Check and set the initial order type if not already set
  //   if (!formValues?.orderType && getOrderTypes?.length > 0) {
  //     onOrderTypeSelect("orderType", getOrderTypes[0].type);
  //   }

  //   // Check and set the initial order time type
  //   // console.log("orderTimeType", orderTimeType);
  //   if (!orderTimeType) {
  //     const initialOrderTimeType =
  //       isAsapAvailable || hotel?.openingAvailable ? "ASAP" : "SCHEDULED";
  //     // console.log("initialOrderTimeType", initialOrderTimeType);
  //     if (orderTimeType !== initialOrderTimeType) {
  //       // console.log("working 11111111", initialOrderTimeType);
  //       // console.log("working 111111", initialOrderTimeType);
  //       setOrderTimeType(initialOrderTimeType);
  //       handleOrderTime(initialOrderTimeType);
  //     }
  //   }
  // }, [
  //   formValues?.orderType,
  //   getOrderTypes,
  //   isAsapAvailable,
  //   hotel?.openingAvailable,
  //   orderTimeType,
  // ]);
  // console.log("new changeeee", newChange);
  useEffect(() => {
    // Check and set the initial order type if not already set
    if (!formValues?.orderType && getOrderTypes?.length > 0) {
      onOrderTypeSelect("orderType", getOrderTypes[0].type);
    }
  }, [formValues?.orderType, getOrderTypes]);

  useEffect(() => {
    if (
      !orderTimeType &&
      selectedOderType &&
      formValues?.orderType &&
      !newChange
    ) {
      // console.log("qorking 222222222");

      const initialOrderTimeType =
        isAsapAvailable || hotel?.openingAvailable ? "ASAP" : "SCHEDULED";
      setOrderTimeType(initialOrderTimeType);
      handleOrderTime(initialOrderTimeType);
    }
  }, [
    selectedOderType,
    isAsapAvailable,
    hotel?.openingAvailable,
    orderTimeType,
    formValues?.orderType,
    newChange,
  ]);

  useEffect(() => {
    if (!newChange) {
      if (!hasMounted.current && formValues) {
        // Initial render logic
        // console.log("isAsapAvailable", isAsapAvailable);
        // console.log("hotel?.openingAvailable", hotel?.openingAvailable);
        // console.log("qorking 1111111");
        const initialOrderTimeType =
          isAsapAvailable &&
          hotel?.openingAvailable &&
          (formValues?.orderType === "DELIVERY" ||
            formValues?.orderType === "PICKUP")
            ? "ASAP"
            : "SCHEDULED";
        // console.log("initialOrderTimeType", initialOrderTimeType);
        handleOrderTime(initialOrderTimeType);
        setOrderTimeType(initialOrderTimeType);
        hasMounted.current = true;
      } else {
        // Update when formValues?.orderType changes
        // console.log("formmmmmmmmmmm", formValues);
        // console.log("isAsapAvailable", isAsapAvailable);
        // console.log("formValues?.orderType", formValues?.orderType);
        const shouldAsapBeDefault =
          isAsapAvailable &&
          hotel?.openingAvailable &&
          (formValues?.orderType === "DELIVERY" ||
            formValues?.orderType === "PICKUP");
        // console.log("shouldAsapBeDefault", shouldAsapBeDefault);

        if (formValues?.orderType) {
          handleOrderTime(shouldAsapBeDefault ? "ASAP" : "SCHEDULED");
          setOrderTimeType(shouldAsapBeDefault ? "ASAP" : "SCHEDULED");
        }
      }
    }
  }, [isAsapAvailable, formValues, hotel?.openingAvailable, newChange]);

  const generateDatePickerSlots = () => {
    let orderTypeValue = value;
    if (value === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      orderTypeValue = ORDER_TYPES.OPENING;
    }
    const dates = generateDateSlots(hotel, orderTypeValue) || [];
    setDateSlots(dates);
    if (!dates || !dates?.length) {
      setDateSlotsError(true);
    }
  };

  useEffect(() => {
    const timeString = `${date}${time ? ` ${time}` : ""}`;
    const dTime = moment(moment(timeString)).toISOString();
    onDateAndTimeSelect(typeOfOrder || "SCHEDULED", dTime);
  }, [date, time, typeOfOrder]);

  useEffect(() => {
    if (hotel || formValues?.orderType || value) {
      generateDatePickerSlots();
    }
  }, [hotel, formValues?.orderType, value]);

  const onClick = () => {
    setNewChange(true);
  };

  const onSelectDataAndTime = ({ value, typeOfOrder } = {}, type) => {
    // console.log("valueeeeee", value);
    // console.log("typeOfOrder", typeOfOrder);
    // console.log("type", type);
    if (!value) {
      return;
    }

    if (type === "date") {
      setDate(value);
    }

    if (type === "time") {
      setTime(value);
    }

    if (typeOfOrder) {
      setTypeOfOrder(typeOfOrder);
    }
  };

  const getLabel = (arry) => {
    const asapSlot = arry.find((item) => item.typeOfOrder === "ASAP");
    return asapSlot ? asapSlot.label : undefined;
  };

  const filterOrderDate = (array) => {
    // console.log("arrayyyyyyyyyy", array);
    return array?.find((arr) => arr?.label === "Today");
  };

  const filteredOrderTime = (array) => {
    // Flatten the array if it's an array of arrays
    // const flatArray = array.flatMap((item) =>
    //   Array.isArray(item) ? item : [item]
    // );

    // Filter the flattened array to find the object with typeOfOrder as "ASAP"
    const result = array.find((arr) => arr?.typeOfOrder === "ASAP");

    return result;
  };

  const filteredSheduledTimes = (array) => {
    // console.log("arrrrrrrrr 22222222222", array);

    // Flatten the array if it's an array of arrays
    // const flatArray = array.flatMap((item) =>
    //   Array.isArray(item) ? item : [item]
    // );

    // Find the object with typeOfOrder as "SCHEDULED"
    const result = array.find((arr) => arr?.typeOfOrder === "SCHEDULED");

    return result;
  };

  const handleOrderTime = (e) => {
    setOrderTimeType(e);
    if (e === "ASAP") {
      const filteredTime = filteredOrderTime(
        getTodayDeliveryIntervalSlots?.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots
      );
      onSelectDataAndTime(filteredTime, "ASAP");
      onSelectDataAndTime(filteredTime, "time");
    } else if (e === "SCHEDULED") {
      const filteredTime = filteredSheduledTimes(
        getTodayDeliveryIntervalSlots?.length > 0
          ? getTodayDeliveryIntervalSlots
          : getDeliveryIntervalSlots
      );
      onSelectDataAndTime(filteredTime, "SCHEDULED");
      onSelectDataAndTime(filteredTime, "time");
    }
  };

  const getDeliveryType = useMemo(() => {
    return selectedOderType;
  }, [selectedOderType]);

  const getLastTime = (array) => {
    if (!array || array.length === 0) {
      return undefined; // Return undefined if array is empty or null
    }

    const lastElement = array[array.length - 1];

    return lastElement?.label; // Return the first key of the last object
  };

  // console.log("resulttttttttt", resukt);

  return (
    <>
      {/* <div className={classes.headerDiv}>
        {theme?.header_logo ? (
          <div className={classes.headerLogoDiv}>
            <img
              src={`data:image/png;base64, ${theme?.header_logo}`}
              alt="Header Logo"
              className={classes.headerLogo}
            />
          </div>
        ) : (
          <Typography
            className={clsx(classes.title)}
            variant="h1"
            display="block"
          >
            {hotel ? hotel.hotelName : ""}
          </Typography>
        )}
      </div> */}
      {isMultipleOrderType && (
        <div className={classes.modalTitleSection}>
          <Typography
            className={classes.modalTitle}
            variant="h1"
            align="center"
            paragraph
          >
            {"Select Order Type"}
          </Typography>
          <Divider />
        </div>
      )}
      {isMultipleOrderType && (
        <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
          {getOrderTypes?.map((o, i) => (
            <div
              key={i}
              className={clsx(
                classes.btnNormal,
                o.type === selectedOderType && classes.item
                // o.active === false && classes.itemDisabled
              )}
              onClick={() => onOrderTypeSelect("orderType", o.type)}
            >
              <div className={classes.itemDiv}>
                <o.Icon
                  className={clsx(
                    o.type === selectedOderType
                      ? classes.itemIcon
                      : classes.normalIcon
                  )}
                />
              </div>
              <Typography
                // color="primary"
                className={clsx(
                  o.type === selectedOderType
                    ? classes.itemText
                    : classes.normalText
                )}
                variant="caption"
              >
                {o.title}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <div className={classes.modalTitleSection} style={{ marginTop: "16px" }}>
        <Typography
          className={classes.modalTitle}
          variant="h1"
          align="center"
          paragraph
        >
          {"Order Time"}
        </Typography>
        <Divider />
      </div>
      <div className={classes.timeSelectDiv}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={orderTimeType}
            name="radio-buttons-group"
            onChange={(e) => handleOrderTime(e.target.value)}
            onClick={onClick}
          >
            <FormControlLabel
              value="ASAP"
              className={classes.radioLabel}
              control={
                <Radio
                  className={classes.radioBtn}
                  classes={{ checked: classes.checked }}
                />
              }
              label={
                !isAsapAvailable ||
                !hotel?.openingAvailable ||
                !getLabel(
                  getTodayDeliveryIntervalSlots.length > 0
                    ? getTodayDeliveryIntervalSlots
                    : getDeliveryIntervalSlots
                )
                  ? "ASAP : Unavailable"
                  : `ASAP : order in ${getLabel(
                      getTodayDeliveryIntervalSlots.length > 0
                        ? getTodayDeliveryIntervalSlots
                        : getDeliveryIntervalSlots
                    )}`
              }
              checked={orderTimeType === "ASAP"}
              disabled={
                !isAsapAvailable ||
                !hotel?.openingAvailable ||
                !getLabel(
                  getTodayDeliveryIntervalSlots.length > 0
                    ? getTodayDeliveryIntervalSlots
                    : getDeliveryIntervalSlots
                )
              }
            />
            <FormControlLabel
              value="SCHEDULED"
              className={classes.radioLabel}
              control={
                <Radio
                  className={classes.radioBtn}
                  classes={{ checked: classes.checked }}
                />
              }
              label="Shedulued for later"
              checked={orderTimeType === "SCHEDULED"}
              // onChange={(e) => handleOrderTime(e.target.value)}
            />
          </RadioGroup>
        </FormControl>
        <div
          id="checkout-orderTypeForm"
          className={clsx(
            orderTimeType === "SCHEDULED"
              ? classes.sheduledContainer
              : classes.hideContainer
          )}
        >
          <DateSlotPicker
            slots={dateSlots}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
          <TimePicker
            slots={
              moment(formValues.deliveryTime).isSame(moment(), "date") &&
              // filteredTimeSlots(
              getTodayDeliveryIntervalSlots.length > 0
                ? getTodayDeliveryIntervalSlots
                : getDeliveryIntervalSlots
              // )
            }
            shouldFilter={true}
            date={date}
            onClick={onClick}
            orderTimeType={orderTimeType}
            className={`w-100 ${classes.dateTimeSelection}`}
            onChange={onSelectDataAndTime}
          />
        </div>

        {getLastTime(
          getTodayDeliveryIntervalSlots.length > 0
            ? getTodayDeliveryIntervalSlots
            : getDeliveryIntervalSlots
        ) &&
          formValues?.deliveryType === "ASAP" &&
          getDeliveryType &&
          isAsapAvailable &&
          hotel?.openingAvailable && (
            <div className={classes.infoDiv}>
              <Info className={classes.infoIcon} />
              <Typography className={classes.infoText}>
                <span style={{ fontWeight: "800" }}>{getDeliveryType}</span>{" "}
                available until{" "}
                <span style={{ fontWeight: "800" }}>
                  {getLastTime(
                    getTodayDeliveryIntervalSlots.length > 0
                      ? getTodayDeliveryIntervalSlots
                      : getDeliveryIntervalSlots
                  )}
                </span>
              </Typography>
            </div>
          )}
      </div>

      {/* {orderTimeType === "shedulued" && ( */}
      {/* )} */}
      <div className={classes.btnDiv}>
        <Button
          disabled={!formValues?.orderType}
          className={classes.continueBtn}
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default SelectOrderType;
