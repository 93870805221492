import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

// const handlerEnabled = false;
// Sample response
// import MenuSampleResponse from "./MenuSampleResponse";

export const getCoupon = async (hotelKey) => {
  const { data } = await axiosInstance.get(
    `${API.ENDPOINTS.GET_COUPON}?user_id=${hotelKey}`,
    {
      handlerEnabled: false,
    }
  );
  // await axiosInstance.get(`${API.ENDPOINTS.GET_MENU}`, {
  //   handlerEnabled: false,
  // });
  // const data = MenuSampleResponse;
  if (!data.status) {
    throw new Error(data.message);
  }
  return { data };
};

// export const validateCoupon = async (hotelId, code) => {
//   const { data } = await axiosInstance.post(
//     `${API.ENDPOINTS.COUPON_VALIDATE}`,
//     {
//       customerId: hotelId,
//       code,
//     },
//     {
//       handlerEnabled: false,
//     }
//   );

//   if (!data.status) {
//     // Return data even if status is false
//     return { data };
//   }

//   return { data };
// };

export const validateCoupon = async (hotelId, code) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.VALIDATE_COUPON}`,
    {
      user_id: hotelId,
      code,
    },
    {
      handlerEnabled: false,
    }
  );

  if (!data.status) {
    throw new Error(data.message);
  }
  return { data };
};
