import React, { Suspense, useEffect } from "react";
import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// Material
import { makeStyles } from "@material-ui/core/styles";
// Routes
import history from "../routes/History";
import Routes from "../routes/Routes";
// Components
import MaterialSnackbar from "../components/Snackbar/Snackbar";
import Loader from "../components/Loader/Loader";

import "./App.scss";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/utils";
import {
  clearAvailableCoupons,
  clearCoupon,
  clearDiscountType,
  clearTipAmount,
  setCouponValidationResult,
  setWalletRedeemed,
  walletModal,
} from "../store/Cart/CartActions";
import { useCookies } from "react-cookie";
import FallBack from "../components/FallBack/FallBack";
import {
  setIsHomeScrolled,
  toggleCategoryHomeMenu,
} from "../store/Menu/MenuActions";
import { setAppMetaData } from "../utils/AppData";
import { useState } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { APP_VERSION } from "../utils/Constants";
import SpecialAlert from "../components/SpecialAlert/SpecialAlert";
import {
  clearUsedWalletPoints,
  resetSelectedWallets,
  resetWallet,
} from "../store/Profile/ProfileActions";
import useWalletPoints from "../CustomHooks/useWalletPoints";
import { resetCheckoutErrorModal } from "../store/Order/OrderActions";
import { setIsMobileApp } from "../store/Restaurant/RestaurantActions";
import { Dialog, DialogContent } from "@material-ui/core";
import LoginDialogCon from "../components/Checkout/LoginDetailsForm/LoginDetailsForm";
import { clearValidAddress } from "../store/Location/LocationActions";

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: theme.palette.common.background,

    backgroundSize: "cover", // Optional: Adjusts the background image to cover the element
    backgroundRepeat: "no-repeat",
    height: "100%",
  },
}));

const useEnvironmentDetect = (flag) => {
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("isMobileApp", flag);
    dispatch(setIsMobileApp(flag));
  }, [flag]);
};

// LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);

const App = () => {
  var afterFirstLoad = false;
  const dispatch = useDispatch();
  const classes = useStyles();
  const customHistory = useHistory();
  const [cookies, removeCookie] = useCookies();
  const { isLoading } = useSelector((state) => state.loader);
  const { version } = useSelector((state) => state.app);
  const { hotel, appVersion } = useSelector((state) => state.hotel);
  const timerRef = useRef(null);
  const { selectedCoupon } = useSelector((state) => state.cart);
  const [updateModal, setUpdateModal] = useState(false);
  const { resetWalletProducts, RemoveItemCoupon } = useWalletPoints();
  console.log("fixed time not changing issue in checkout");

  const isFlutterWebView = window.isFlutterWebView || false;
  // console.log("isFlutterWebView", isFlutterWebView);
  // console.log("navigator.userAgent", navigator.userAgent);
  // const isFlutterWebView2 = navigator.userAgent.includes(
  //   "CustomUserAgent/FlutterWebView"
  // );
  // console.log("isFlutterWebView2", isFlutterWebView2);

  useEffect(() => {
    // Ensure overflow is not hidden
    const removeOverflowHidden = () => {
      document.body.style.overflow = "auto";
    };

    // Remove overflow hidden on initial load
    removeOverflowHidden();

    // Listen for changes in the style attribute of the body tag
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          removeOverflowHidden();
        }
      });
    });

    // Observe the body element for attribute changes
    observer.observe(document.body, {
      attributes: true,
    });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  // useEnvironmentDetect(isFlutterWebView2);
  useEnvironmentDetect(isFlutterWebView);

  const generateUniqueId = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uniqueId = "";

    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }
    return uniqueId;
  };

  // useEffect(() => {
  //   // Replace 'user123' with your actual user ID from your authentication system
  //   const userId = generateUniqueId();
  //   dispatch(setUserUniqueId(userId));
  //   // Fetch user information (name, email, etc.) from your authentication system

  //   // Identify the user in LogRocket
  //   LogRocket.identify(userId);
  // }, []);

  // Function to reload the page
  const reloadPage = () => {
    // localStorage.setItem("cartData", JSON.stringify(data));
    window.location.reload();
  };

  useEffect(() => {
    const resetTimer = () => {
      // Clear the previous timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer to reload the page after 5 minutes
      timerRef.current = setTimeout(reloadPage, 300000); // 5 minutes in milliseconds
    };

    // Attach a mousemove event listener to reset the timer on mouse movement
    window.addEventListener("mousemove", resetTimer);

    // Initial timer setup
    resetTimer();

    // Clean up the event listener and timer when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener("mousemove", resetTimer);
    };
  }, []);

  window.fcmTrigger = (val) => {
    localStorage.setItem("fcmToken", val);
  };

  // useEffect(() => {
  //   if (afterFirstLoad || !APP_VERSION) {
  //     return;
  //   }

  //   dispatch(toggleCategoryHomeMenu(false)); //hide the category menu above home page on initial load
  //   setAppMetaData(); //set app meta data
  //   // need to change this block of code based on proper version number
  //   // const newVersion = version.find(v => v.type === ONLINE_ORDER_WEB);

  //   const localVersion = getLocalStorageItem("version");
  //   if (!APP_VERSION) {
  //     return;
  //   }

  //   // console.log("new versionnnnnn", newVersion.version)
  //   // console.log("local versionnnnn", localVersion)

  //   afterFirstLoad = true;
  //   if (!localVersion || localVersion !== APP_VERSION) {
  //     for (let key of Object.keys(cookies)) {
  //       removeCookie(key, { path: "/" });
  //     }

  //     clearEntireLocalStorage();
  //     setLocalStorageItem("version", APP_VERSION);
  //     window.location.reload(true);
  //   }

  //   dispatch(clearCoupon());
  //   dispatch(clearAvailableCoupons());
  // }, [APP_VERSION]);

  // console.log("appVersion?.version", appVersion?.version);

  useEffect(() => {
    if (afterFirstLoad || appVersion?.version == undefined) {
      return;
    }

    dispatch(toggleCategoryHomeMenu(false)); // Hide the category menu above the home page on initial load
    setAppMetaData(); // Set app meta data

    const localVersion = getLocalStorageItem("version");

    afterFirstLoad = true;
    // console.log("appVersion?.version", appVersion?.version);
    if (appVersion?.version !== APP_VERSION) {
      for (let key of Object.keys(cookies)) {
        removeCookie(key, { path: "/" });
      }

      const appStateString = localStorage.getItem("appState");

      if (appStateString) {
        try {
          // Extract "user" data from "appState"
          const appState = JSON.parse(appStateString);
          const user = appState.user;

          // Clear the entire local storage
          localStorage.clear();

          // Set "appState" with preserved "user" data back to local storage
          appState.user = user;
          localStorage.setItem("appState", JSON.stringify(appState));
        } catch (error) {
          console.error("Error processing appState data:", error);
        }
      }

      setLocalStorageItem("version", appVersion?.version);
      setLocalStorageItem("envversion", APP_VERSION);
      setUpdateModal(true);
    }
    setLocalStorageItem("version", appVersion?.version);
    dispatch(clearUsedWalletPoints());
    if (selectedCoupon?.id) {
      RemoveItemCoupon(selectedCoupon);
    }
    dispatch(clearCoupon());
    resetWalletProducts(1);
    dispatch(clearDiscountType());
    dispatch(resetWallet());
    dispatch(clearAvailableCoupons());
    dispatch(setWalletRedeemed(false));
    dispatch(walletModal(false));
    dispatch(clearTipAmount());
    dispatch(resetSelectedWallets());
    dispatch(resetCheckoutErrorModal());
    dispatch(clearValidAddress());
    dispatch(setIsHomeScrolled(false));
    dispatch(setCouponValidationResult(false));
  }, [appVersion]);

  window.addEventListener("beforeunload", function () {
    // This code will run just before the page is reloaded
    localStorage.removeItem("modalShown"); // Remove the item from localStorage
  });

  window.addEventListener("beforeunload", function () {
    // This code will run just before the page is reloaded
    localStorage.removeItem("categoryModalShown"); // Remove the item from localStorage
  });

  window.addEventListener("beforeunload", function () {
    // This code will run just before the page is reloaded
    localStorage.removeItem("orderType"); // Remove the item from localStorage
  });

  const handleClose = () => {
    setUpdateModal(false);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <>
        {isLoading ? <Loader /> : null}
        <div className={clsx(classes.bgColor)}>
          <Suspense fallback={<FallBack />}>
            <Router history={history}>
              <MaterialSnackbar />
              {<Routes />}
            </Router>
          </Suspense>
          <SpecialAlert
            open={updateModal}
            title={"We have a new version please update"}
            handleClose={handleClose}
            updateBtn={true}
          />
        </div>
      </>
    </React.Fragment>
  );
};

export default App;
